import * as React from 'react';
import { PanResponder, View, } from 'react-native';
export default class UserInactivity extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            active: true,
        };
        this.clearTimer = () => {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
        };
        /**
         * This method is called whenever a touch is detected. If no touch is
         * detected after `this.props.timeForInactivity` milliseconds, then
         * `this.state.inactive` turns to true.
         */
        this.handleInactivity = () => {
            this.clearTimer();
            this.setState({
                active: true,
            }, () => {
                this.props.onAction(this.state.active); // true
            });
            this.resetTimeout();
        };
        /**
         * If more than `this.props.timeForInactivity` milliseconds have passed
         * from the latest touch event, then the current state is set to `inactive`
         * and the `this.props.onInactivity` callback is dispatched.
         */
        this.timeoutHandler = () => {
            this.setState({
                active: false,
            }, () => {
                this.props.onAction(this.state.active); // false
            });
        };
        this.resetTimeout = () => {
            this.timeout = setTimeout(this.timeoutHandler, this.props.timeForInactivity);
        };
        this.onShouldSetPanResponderCapture = () => {
            this.handleInactivity();
            /**
             * In order not to steal any touches from the children components, this method
             * must return false.
             */
            return false;
        };
    }
    componentWillMount() {
        this.panResponder = PanResponder.create({
            onMoveShouldSetPanResponderCapture: this.onShouldSetPanResponderCapture,
            onPanResponderTerminationRequest: this.onShouldSetPanResponderCapture,
            onStartShouldSetPanResponderCapture: this.onShouldSetPanResponderCapture,
        });
        this.handleInactivity();
    }
    componentWillUnmount() {
        this.clearTimer();
    }
    render() {
        const { style, children, } = this.props;
        return (<View style={style} collapsable={false} {...this.panResponder.panHandlers}>
        {children}
      </View>);
    }
}
UserInactivity.defaultProps = {
    style: {
        flex: 1,
    },
    timeForInactivity: 10000,
};
